import loadable from "@loadable/component";
import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import i18n from "i18next";
import { inject } from "mobx-react";
import { RecommendationBlockValueResolver, isPrimaryBlock } from "@ihr-radioedit/inferno-core";
import type { RecommendationProps } from "../../lib/recommendation";
import { isHideOnMobileBlock } from "../../services/Sites.utils";
import { Container, Heading } from "../../ui";
import "./Recommendation.style.scss";
import { isItemBlock, isFeedResult, isPublishRecord } from "@inferno/renderer-shared-core";

const OutbrainWidget = loadable(() => import("./Outbrain.component"));

export const Recommendation = inject("store")(({ block, store, page }: RecommendationProps) => {
  const value = RecommendationBlockValueResolver.decode(block.value);
  if (isLeft(value) || !store) {
    return null;
  }

  const { site, request } = store;
  const { widget_id } = value.right;
  const permalink = site.getShareUrl(request);
  let sponsored = false;
  let contentRecommendation = true;
  const recommendationClass = classnames("component-recommendation", {
    "not-for-mobile": isHideOnMobileBlock(block.tags || undefined),
  });
  const currentBlock = page?.blocks.find(b => isPrimaryBlock(b.tags));

  if (isItemBlock(currentBlock) && isFeedResult(currentBlock.item?.result)) {
    const item = currentBlock.item?.result.record;
    if (!isPublishRecord(item)) {
      return null;
    }
    const { payload } = item;
    sponsored = !!payload?.is_sponsored;
    contentRecommendation = !!payload?.include_recommendations;
  }

  if (!sponsored && site.sections.partners?.outbrain_switch && contentRecommendation) {
    return (
      <Container className={recommendationClass}>
        <Heading level={3}>{i18n.t("recommendation")}</Heading>
        <OutbrainWidget dataSrc={permalink} dataWidgetId={widget_id} />
      </Container>
    );
  }

  return null;
});

export default Recommendation;
